<template>
    <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" ref="toast_element">
        <div class="toast-header">
            <!--<img src="..." class="rounded me-2" alt="...">-->
            <strong class="me-auto">{{ toast.title }}</strong>
            <small class="text-muted" v-if="timestamp < 10">
                Agora
            </small>
            <small class="text-muted" v-if="timestamp > 10">
                há {{ tempo_decorrido }}
            </small>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="close"
                v-if="toast.show_close_button"></button>
        </div>
        <div class="toast-body">
            {{ toast.body }}
        </div>
    </div>
</template>
<script lang="ts" setup>
import humanizeDuration from 'humanize-duration'
import { Toast as ToastType } from '@/store/toasts';
import { Toast } from 'bootstrap';
import { computed } from 'vue';
import { onBeforeUnmount } from 'vue';
import { onMounted, ref } from 'vue';

interface Props {
    toast: ToastType
}

const props = defineProps<Props>()
const emits = defineEmits<{
    (e: 'close', id: string): void
}>()

const close = async () => {
    emits('close', props.toast.id)
}

const toast_element = ref<HTMLDivElement>()

const timestamp = ref<number>(Math.round(((new Date()).getTime() - props.toast.started_at.getTime()) / 1000) * 1000)

const tempo_decorrido = computed(() => {
    return humanizeDuration(timestamp.value, {
        language: 'pt'
    })
})

let timer: number|null = null
const updateTimestamp = () => {
    console.log(timestamp.value, 'timestamp')
    timestamp.value = Math.round(((new Date()).getTime() - props.toast.started_at.getTime())/1000)*1000
    if (timer) {
        console.log('clear timer')
        window.clearTimeout(timer)
    }
    console.log('next timestamp')
    timer = window.setTimeout(updateTimestamp, 1000)
}
onMounted(() => {
    if (!toast_element.value) {
        return
    }
    const toast = new Toast(toast_element.value, {
        autohide: false
    })
    toast.show()
    updateTimestamp()
})

onBeforeUnmount(() => {
    console.log('umount')
    if (timer) {
        window.clearTimeout(timer)
    }
})

</script>