import { createRouter, createWebHashHistory } from 'vue-router';

import routes from '~pages'
import api from '@/lib/api'


export const router = createRouter({
    history: createWebHashHistory(),
    routes,
});




api.interceptors.response.use(response => {
    return response;
}, err => {
    if (err?.response?.status == 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        router.replace('/login');
    }
    return Promise.reject(err);
})

export default router;