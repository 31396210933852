<template>
    
        <div class="toast-container position-absolute bottom-0 end-0 p-3">
            <ToastItem :toast="toast" v-for="toast in toasts" @close="close"></ToastItem>
        </div>
    
</template>
<script lang="ts" setup>

import { toasts } from '@/store/toasts'
import ToastItem from './ToastItem.vue';

const close = (id: string) => {
    toasts.value = toasts.value.filter(row => {
        return row.id !== id
    })
}

console.log(toasts.value)

</script>