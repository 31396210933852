import Swal from "sweetalert2";
import api from '@/lib/api'
import { ReplicationService } from './replication-service'
import { ref } from "vue";
import { toasts } from "@/store/toasts";
import { ulid } from 'ulid'
export * from './replication-service'

export const replication_service = new ReplicationService(api)
export const sincronizing = ref<boolean>(false)


export const sync = async () => {
    let id = ulid()
    try {
        if (sincronizing.value) {
            return
        }
        sincronizing.value = true
        toasts.value.push({
            id,
            started_at: new Date(),
            title: "Sincronizando...",
            body: "Executando sincornização, não feche a janela",
            show_close_button: false
        })       

        await replication_service.sync()
        Swal.fire({
            icon: 'success',
            title: "Sincronização concluída com sucesso!"
        })
        toasts.value = toasts.value.filter(row => {
            row.id != id
        })
    } catch (error) {
        if (error instanceof Error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: error.message
            })
        }
    }
    sincronizing.value = false
    toasts.value = toasts.value.filter(row => {
        row.id != id
    })
}

window.onbeforeunload = function () {
    if (!sincronizing.value) {
        return
    }
    return "Existe uma tarefa de sincronização em andamento, não é recomendado fechar a janela. Deseja prosseguir mesmo assim?"
    
}