import axios from 'axios';
import { ulid } from 'ulid';



export const api = axios.create({
    baseURL: `${import.meta.env.VITE_APPLICATION_PATH ?? ''}api`,
    //baseURL: `http://localhost:39219/api`,
    //baseURL: 'https://app.auditaestoque.com.br/api'
});



api.interceptors.request.use(config => {
    if (localStorage.getItem('access_token')) {
        let access_token = localStorage.getItem('access_token');
        if (!config.params) {
            config.params = {};
        }
        config.params.access_token = access_token;
    }
    let params = {
        ...config.params,
        request_id: ulid()
    }
    return {
        ...config,
        params
    };
}, err => {

    return Promise.reject(err);
})

api.interceptors.response.use(response => response, error => {
    if (error.response?.data?.error) {
        return Promise.reject(new Error(error.response.data.error))
    }
    return Promise.reject(error)
})


export default api;
