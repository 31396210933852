import type { AxiosInstance } from "axios";
import { Filter } from "./filter";
import type { Usuario } from "./usuario";
import { Pagination } from "@/store/pagination" 
import { EventEmitter } from "@/lib/event-emitter";

export class UsuarioRepository {
    private ev: EventEmitter

    constructor(
        private api: AxiosInstance
    ) {
        this.ev = new EventEmitter()
    }

    async search(filter: Filter = new Filter(), pagination?: Pagination): Promise<Usuario[]> {
        const { data } = await this.api.get<Usuario[]>(`/usuarios/search`, {
            params: {
                ...filter,
                ...pagination
            }
        })
        return data
    }

    async searchOne(filter: Filter = new Filter()): Promise<Usuario | null> {
        const { data } = await this.api.get<Usuario | null>(`/usuarios/search-one`, {
            params: {
                ...filter
            }
        })
        return data
    }

    async count(filter: Filter = new Filter()): Promise<number> {
        const { data } = await this.api.get<number>(`/usuarios/count`, {
            params: {
                ...filter
            }
        })
        return data
    }

    async getById(id: string): Promise<Usuario | null> {
        return this.searchOne({ id })
    }

    async getByEmail(email: string): Promise<Usuario | null> {
        return this.searchOne({ email })
    }

    async save(record: Usuario): Promise<Usuario> {
        const { data } = await this.api.post<Usuario>(`/usuarios/save`, record)
        this.ev.emit('saved')
        this.ev.emit('changed')
        this.ev.emit('*')
        return data
    }

    async remove(ids: string[]): Promise<void> {
        await this.api.delete(`/usuarios/remove`, {
            data: {
                ids
            }
        })
        this.ev.emit('removed')
        this.ev.emit('changed')
        this.ev.emit('*')
    }

    addEventListener(event: 'saved' | 'removed' | 'changed' | '*', callback: Function) {
        this.ev.on(event, callback)
    }
}