<template>
    <div></div>
</template>
<script lang="ts" setup>

import { current_user } from '@/store/auth'
import { TipoUsuario } from '@/store/usuarios'
import { useRouter } from 'vue-router'
const router = useRouter()

if (current_user.value) {
    if ([TipoUsuario.ADMINISTRADOR, TipoUsuario.MASTER, TipoUsuario.COORDENADOR].includes(current_user.value.tipo)) {        
        router.replace('/resultados')        
    } else {
        router.replace('/home')
    }
}


</script>